'use client';

import styled from 'styled-components';

import BaseButton from '@/src/components/BaseComponents/BaseButton';
import Consts from '@/src/lib/ErrorFallbackConsts';
import theme from '@/theme';

const {ERROR_TITLE, BUTTON_TEXT} = Consts;

interface Props {
  error: Error & {digest?: string};
  reset: () => void;
}

export default function Error({error, reset}: Props) {
  return (
    <Wrapper role="alert">
      <h2>{ERROR_TITLE}</h2>
      <div>{error?.message}</div>
      <Button onClick={reset}>{BUTTON_TEXT}</Button>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const Button = styled(BaseButton)`
  padding: 8px 36px;
  background-color: ${theme.colors.grey000};
  border: none;
  color: ${theme.colors.blue093};

  &:hover {
    background-color: ${theme.colors.green054};
    border: none;
    color: ${theme.colors.grey000};
  }
`;
